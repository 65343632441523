import { RouteConfig } from "vue-router";
import { Permission } from "@/utils/permissions";

const ConfigurationsRoutes: Array<RouteConfig> = [
  {
    path: "",
    name: "Configurations",
    component: () =>
      import("@/views/Configurations/Generals/Configurations.vue"),
    meta: {
      permissionsRequired: [Permission.LIST_CLIENT],
      requiresAdmin: true,
    },
  },
  {
    path: "/leads_center",
    name: "Leads",
    component: () => import("@/views/Configurations/LeadsCenter.vue"),
    meta: {
      requiresLogged: true,
    },
  },
  {
    path: "/leads_center/new_lead",
    name: "New Lead",
    component: () => import("@/views/Configurations/components/AddLead.vue"),
    meta: {
      requiresLogged: true,
    },
  },
  {
    path: "/leads_center/import_lead",
    name: "Import Lead",
    component: () => import("@/views/Configurations/components/ImportLead.vue"),
    meta: {
      requiresLogged: true,
    },
  },
  {
    path: "/leads_center/google_sheet_list",
    name: "Google Sheet List",
    component: () =>
      import("@/views/Configurations/components/GoogleSheetList.vue"),
    meta: {
      requiresSuper: true,
    },
  },
  {
    path: "/leads_center/google_sheet/create",
    name: "Google Sheet",
    component: () =>
      import("@/views/Configurations/components/GoogleSheet.vue"),
    meta: {
      requiresSuper: true,
    },
  },
  {
    path: "/leads_center/google_sheet/edit",
    name: "Google Sheet",
    component: () =>
      import("@/views/Configurations/components/GoogleSheetEdit.vue"),
    meta: {
      requiresSuper: true,
    },
  },
  {
    path: "/leads_center/edit/:uuid",
    name: "Lead_Edit",
    component: () => import("@/views/Configurations/components/LeadEdit.vue"),
    meta: {
      requiresLogged: true,
    },
  },
  {
    path: "/leads_details/:uuid",
    name: "Lead Details",
    component: () =>
      import("@/views/Configurations/components/LeadDetails.vue"),
    meta: {
      requiresLogged: true,
    },
  },
  {
    path: "/details_lead_summary/:query",
    name: "Details LeadSummary",
    component: () =>
      import("@/views/Configurations/Finances/DetailsLeadSummary.vue"),
    meta: {
      requiresLogged: true,
    },
  },
  {
    path: "/reports/other_payments",
    name: "Other Payments",
    component: () =>
      import("@/views/Configurations/Finances/DetailsUserOtherSales.vue"),
    meta: {
      requiresLogged: true,
    },
  },
  {
    path: "/details_user_sales/:query",
    name: "Details UserSales",
    component: () =>
      import("@/views/Configurations/Finances/DetailsUserSales.vue"),
    meta: {
      requiresLogged: true,
    },
  },

  {
    path: "/reports",
    name: "Reports",
    component: () =>
      import(
        /* webpackChunkName: "reports" */ "@/views/Configurations/Reports.vue"
      ),
    meta: {
      requiresAdmin: true,
    },
  },

  {
    path: "/payments-generals",
    name: "PaymentsGenerals",
    component: () =>
      import(
        /* webpackChunkName: "paymentsgenerals" */ "@/views/Configurations/Payments/PaymentsGenerals.vue"
      ),
    meta: {
      requiresLogged: true,
    },
  },
  {
    path: "/quotes-general",
    name: "QuotesGeneral",
    component: () =>
      import(
        /* webpackChunkName: "quotesgeneral" */ "@/views/Configurations/QuotesGeneral.vue"
      ),
    meta: {
      requiresLogged: true,
    },
  },
  {
    path: "/sell-accessories",
    name: "Sell-Accessories",
    component: () =>
      import(
        /* webpackChunkName: "sellaccessories" */ "@/views/Configurations/SellAccessories.vue"
      ),
    meta: {
      requiresLogged: true,
    },
  },
  {
    path: "/clock-in-check",
    name: "Clockin-Check",
    component: () =>
      import(
        /* webpackChunkName: "clockin-check" */ "@/views/Configurations/Clock/ClockIn-Control.vue"
      ),
    meta: {
      requiresLogged: true,
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: () =>
      import(/* webpackChunkName: "Profile" */ "@/views/UserProfile.vue"),
    meta: {
      requiresLogged: true,
    },
  },
  /*   {
    path: "/contacts",
    name: "User Contacts",
    component: () =>
      import(
         "@/views/UserContacts.vue"
      ),
    meta: {
      requiresLogged: true,
    },
  }, */
  {
    path: "/logs",
    name: "Logs",
    component: () =>
      import(/* webpackChunkName: "Logs" */ "@/views/Configurations/Logs.vue"),
    meta: {
      requiresSuper: true,
    },
  },
  {
    path: "/logs/log/:uuid",
    name: "log_view",
    component: () =>
      import(
        /* webpackChunkName: "Logs" */ "@/views/Configurations/ViewLogs.vue"
      ),
    meta: {
      requiresSuper: true,
    },
  },
];

export default ConfigurationsRoutes;

import { RouteConfig } from "vue-router";
import { Permission } from "@/utils/permissions";

const WorkersRoutes: Array<RouteConfig> = [
  {
    path: "",
    name: "Patient list",
    component: () => import("@/views/Patients/Patients.vue"),
    meta: {
      requiresLogged: true,
    },
  },
  {
    path: "/relationship",
    name: "Transfer Patient",
    component: () => import("@/views/Patients/TransferPatient.vue"),
    meta: {
      requiresAdmin: true,
    },
  },
  {
    path: "create",
    name: "Create patient",
    component: () => import("@/views/Patients/FormCreate.vue"),
    meta: {
      requiresLogged: true,
    },
  },
  {
    path: "update/:uuid",
    name: "Update patient",
    component: () => import("@/views/Patients/FormUpdate.vue"),
    meta: {
      requiresLogged: true,
    },
  },
  {
    path: "details/:uuid",
    name: "Patient ddetails",
    component: () => import("@/views/Patients/Details.vue"),
    meta: {
      requiresLogged: true,
    },
  },
];

export default WorkersRoutes;

import Vue from "vue";
import App from "@/App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.min.css";
import "./sass/app.scss";
import i18n from "./i18n";
import DeviceProvider from "./mixins/deviceProvider";
//comment for merge prestige
// Plugins
import VueAnime from "vue-animejs";
import VEmojiPicker from "v-emoji-picker";
import WaveSurferVue from "wavesurfer.js-vue";
import Vue2Filters from "vue2-filters";

import "@/filters";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
library.add(fab);

// import { TiptapVuetifyPlugin } from "tiptap-vuetify";
// import "tiptap-vuetify/dist/main.css";

Vue.use(VEmojiPicker);
Vue.use(VueAnime);
Vue.use(WaveSurferVue);
Vue.use(Vue2Filters);

Vue.component("font-awesome-icon", FontAwesomeIcon);
// Vue.use(TiptapVuetifyPlugin, {
//   vuetify,
//   iconsGroup: "mdi",
// });

new Vue({
  router,
  store,
  vuetify,

  i18n,
  mixins: [DeviceProvider],
  render: (h) => h(App),
}).$mount("#app");


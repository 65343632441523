/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
import { getAPI } from "@/api/axios-base";
import {
  notificationError,
  notifyError,
  notifyInfo,
} from "@/components/Notification";
import { isDevelop } from "@/enviorment";
import { PatientStatus } from "@/models/Patient";
import store from "@/store";

export async function actListStates({ commit }: any): Promise<any> {
  commit("mutLoadingStates", true);

  try {
    const responseData = (await getAPI("/patient/listStates")).data;

    commit("mutSetStates", responseData);
    commit("mutLoadingStates", false);
  } catch (error: any) {
    commit("mutLoadingStates", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}

export async function actGetEducationLevels({ commit }: any): Promise<any> {
  commit("mutLoadingEducationLevels", true);

  try {
    const responseData = (await getAPI("/patient/getEducationLevels")).data;

    commit("mutSetEducationalLevels", responseData);
    commit("mutLoadingEducationLevels", false);
  } catch (error: any) {
    commit("mutLoadingEducationLevels", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}

export async function actGetMedicalLocations({ commit }: any): Promise<any> {
  commit("mutLoadingMedicalLocations", true);

  try {
    const responseData = (await getAPI("/patient/getMedicalLocations")).data;

    commit("mutSetMedicalLocations", responseData);
    commit("mutLoadingMedicalLocations", false);
  } catch (error: any) {
    commit("mutLoadingMedicalLocations", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}

export async function actListPatients(
  { commit }: any,
  reqrange?: any
): Promise<any> {
  commit("mutLoading", true);
  const rrange = reqrange ? reqrange : { limit: 100, offset: 0 };
  try {
    const responseData = (
      await getAPI(
        `/patient/listPatients?limit=${rrange.limit}&offset=${rrange.offset}`
      )
    ).data;

    commit("mutTotalPatient", responseData.count);
    commit("mutSetPatients", responseData.result);
    commit("mutLoading", false);
  } catch (error: any) {
    commit("mutLoading", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}
export async function actListPatientsFilter(
  { commit }: any,
  reqrange?: any
): Promise<any> {
  commit("mutLoading", true);
  const rrange = reqrange ? reqrange : { limit: 100, offset: 0 };
  try {
    let responseData = (
      await getAPI(
        `/patient/listPatients?limit=${rrange.limit}&offset=${rrange.offset}`
      )
    ).data;
    const cant = responseData.count;
    if (cant > responseData.result.length) {
      responseData = (
        await getAPI(
          `/patient/listPatients?limit=${cant}&offset=${rrange.offset}`
        )
      ).data;
    }

    commit("mutTotalPatient", responseData.count);
    commit("mutSetPatients", responseData.result);
    commit("mutLoading", false);
  } catch (error: any) {
    commit("mutLoading", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}

export async function actGetCityByState(
  { commit }: any,
  stateId: number
): Promise<any> {
  try {
    const responseData = (await getAPI("/patient/getStateCities/" + stateId))
      .data;

    return responseData;
  } catch (error: any) {
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}

export async function actGetPatientDoctors({ commit }: any): Promise<any> {
  commit("mutLoadingDoctors", true);

  try {
    const responseData = (await getAPI("/users/getDoctors")).data;

    commit("mutSetDoctors", responseData);

    commit("mutLoadingDoctors", false);
  } catch (error: any) {
    commit("mutLoadingDoctors", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}
export async function actGetPatientAgents({ commit }: any): Promise<any> {
  commit("mutLoadingDoctors", true);
  try {
    const responseData = (
      await getAPI.post("/users/filterUsers", {
        rol: "WORKER",
        limit: 100,
        offset: 0,
      })
    ).data.result;

    commit("mutSetAgents", responseData);
    commit("mutLoadingDoctors", false);
  } catch (error: any) {
    commit("mutLoadingDoctors", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}

export async function actCreateNomenclator(
  { commit, dispatch }: any,
  {
    entity,
    name,
  }: {
    entity: string;
    name: string;
  }
): Promise<any> {
  commit("mutLoading", true);

  try {
    await getAPI.post(`/patient/create${entity}`, {
      name: name,
    });
    await dispatch(`actGet${entity}s`);
    commit("mutLoading", false);
  } catch (error: any) {
    isDevelop && console.log(`actCreate${entity}`, error);
    commit("mutLoading", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}

export async function actRemovePatient(
  { commit, dispatch }: any,
  uuid: string
): Promise<any> {
  commit("mutLoading", true);
  try {
    await getAPI.delete("/patient/deletePatient/" + uuid);
    dispatch("actListPatients");
    commit("mutLoading", true);
  } catch (error: any) {
    commit("mutLoading", true);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}

export async function actFilterPatient(
  { commit }: any,
  body: any
): Promise<any> {
  commit("mutLoading", true);
  const req = cleanNull(body);
  try {
    const responseData = (await getAPI.post("/patient/filterPatients", req))
      .data;

    commit("mutTotalPatient", responseData.count);
    commit("mutSetPatients", responseData.result);
    commit("mutLoading", false);
    return responseData;
  } catch (error: any) {
    commit("mutLoading", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}

export async function getPhotoId({ commit }: any, uuid: string): Promise<any> {
  try {
    const photoId = getAPI("/patient/getPhotoAuthForm/" + uuid);

    return photoId;
  } catch (error) {
    //
  }
}

export async function actUpdatePatientStatus(
  { commit }: any,
  { uuid, status }: { uuid: string; status: PatientStatus }
): Promise<any> {
  await getAPI.put(`/patient/updatePatient/${uuid}`, {
    dashboardStatus: status,
  });
  notifyInfo("State updated successfully");
}

export async function actCreateprocedure(
  { commit }: any,
  body: { name: string; patientUuid: string }
): Promise<any> {
  const responseData = (await getAPI.post("/patient/createProcedure", body))
    .data;
  commit("mutProcedure", responseData);
}

//Quotes**************************

export async function actCreateQuote(
  { commit }: any,
  quote: any
): Promise<any> {
  try {
    commit("mutLoading", true);
    const responseData = (await getAPI.post("/sprocedures/create-quote", quote))
      .data;

    commit("mutLoading", false);
    return responseData;
  } catch (error: any) {
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);

    commit("mutLoading", false);
  }
}

export async function actAproveQuote({ commit }: any, id: number) {
  try {
    commit("mutLoading", true);
    await getAPI.put("/sprocedures/aprove/" + id);
  } catch (error: any) {
    commit("mutLoading", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}

export async function actSalesAccessories({ commit }: any, req: any) {
  try {
    commit("mutLoading", false);
    const responseData = (await getAPI.post("/sales/filterList", req)).data;
    commit("mutSalesAccessories", responseData.result);
    commit("mutLoading", false);
  } catch (error: any) {
    commit("mutLoading", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}

function cleanNull(obj: any) {
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === ""
    ) {
      delete obj[propName];
    }
  }
  return obj;
}

<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <template v-if="loggedIn">
      <div class="the-aside">
        <v-list shaped dense dark>
          <v-list-item-group color="primary">
            <template v-for="(link, index) in dataMenu">
              <v-list-item
                v-if="link.showIf && !link.hide"
                :to="link.href"
                :key="index"
              >
                <v-list-item-icon>
                  <v-icon>{{ link.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="link.title"></v-list-item-title>
                </v-list-item-content>
                <template v-if="link.title == 'SMS' && containunread">
                  <v-list-item-action>
                    <v-icon color="green lighten-1">mdi-information</v-icon>
                  </v-list-item-action>
                </template>
              </v-list-item>
            </template>
          </v-list-item-group>
        </v-list>
        <div class="version mx-2">
          <v-spacer></v-spacer>
          <span class="white--text" :class="drawerExpanded ? 'expandedver' : ''"
            >version 1.1.2</span
          >
        </div>
      </div>

      <audio id="audio" controls>
        <source type="audio/wav" src="@/assets/sounds/notification.mp3" />
      </audio>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState, mapMutations } from "vuex";

import { GET_SIDE_MENU } from "@/store/getters/types";
import { ACT_GENERAL_CONFIG } from "@/store/actions/types";
import axios from "axios";
import { apiBaseUrl } from "@/enviorment";
export default {
  name: "the-aside",
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types

  computed: {
    ...mapGetters([GET_SIDE_MENU, "loggedIn", "getsoundNotify"]),
    ...mapState("crmSMSModule", ["containunread", "unreads"]),
    ...mapState(["drawerExpanded"]),
  },
  watch: {
    unreads(val) {
      if (val.length == 0) {
        this.mutContainUnread(false);
      }
    },
    containunread(val) {
      if (val && this.getsoundNotify) {
        this.playSound();
      }
    },
  },
  methods: {
    ...mapActions({ act_config: ACT_GENERAL_CONFIG }),
    ...mapMutations("crmSMSModule", ["mutContainUnread"]),

    playSound() {
      var audio = document.getElementById("audio");
      audio.play();
    },
  },
  mounted() {
    this.act_config();
  },
};
</script>
<style lang="scss" scope>
.the-aside {
  margin-bottom: 100px !important;
}
.version {
  bottom: 0 !important;
  display: flex;
  justify-content: flex-end;
}
.expandedver {
  font-size: 10px;
}
#audio {
  display: none;
}
</style>

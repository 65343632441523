<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <v-snackbar
      v-model="dialogMakecall"
      color="secondary"
      :right="right"
      :left="left"
      auto-height
      content-class="snackcall"
      :top="mini"
      :centered="mini"
      elevation="15"
      :timeout="-1"
    >
      <div v-show="!mini">
        <div class="d-flex">
          <v-btn v-if="!left" color="white" icon @click="toLeft"
            ><v-icon>mdi-arrow-left-bold</v-icon></v-btn
          >
          <template v-if="callUi == 0">
            <v-spacer></v-spacer>
            <v-btn color="error" icon @click="toClose"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </template>
          <template v-else>
            <v-spacer></v-spacer>
            <v-btn color="error" icon @click="toMini"
              ><v-icon>mdi-window-minimize</v-icon></v-btn
            >
          </template>

          <v-spacer></v-spacer>

          <v-btn v-if="!right" color="white" icon @click="toRight"
            ><v-icon>mdi-arrow-right-bold</v-icon></v-btn
          >
        </div>
        <v-card elevation="0" dark class="aside-call secondary">
          <v-card-title class="headline">
            {{ callUi == 0 ? $t("title") : $t("callInProgress") }}
          </v-card-title>
          <v-window class="call-ui" v-model="callUi">
            <v-window-item>
              <v-layout column>
                <v-divider />

                <dialer
                  class="mt-2"
                  :value="number"
                  @make-call="makeCall($event)"
                />
              </v-layout>
            </v-window-item>
            <v-window-item>
              <v-layout column>
                <call-in-progress
                  ref="callprogres"
                  :leadUuid="leadUuid"
                  :number="number"
                  @hangup="hangup"
                  @errorcall="errorcaller"
                  @maximize="maximizeWin"
                />
              </v-layout>
            </v-window-item>
            <v-window-item>
              <v-layout column justify-center>
                <h4 class="d-flex justify-center">Calling to: {{ number }}</h4>
                <h5 :class="`${colors}--text`" class="d-flex justify-center">
                  {{ $t(message) }}
                </h5>
                <template v-if="message != 'errorcall'">
                  <div class="d-flex justify-center my-3">
                    <v-progress-linear
                      color="green"
                      buffer-value="0"
                      stream
                    ></v-progress-linear>
                  </div>
                </template>

                <template v-if="message == 'errorcall'">
                  <v-btn class="my-1" @click="makeCall" color="warning">
                    <v-icon>mdi-phone-in-talk</v-icon> Retry</v-btn
                  >
                </template>
                <v-btn class="my-1" @click="hangup" color="error">
                  <v-icon>mdi-phone-hangup</v-icon> Cancel</v-btn
                >
              </v-layout>
            </v-window-item>
          </v-window>
        </v-card>
      </div>
      <div v-show="mini">
        <v-row class="cloudcall">
          <v-col>
            {{ callTime }}
          </v-col>
          <v-col class="d-flex">
            <v-btn
              @click="minMute"
              :color="mutedCall ? 'red' : 'white'"
              icon
              class="mutecall"
            >
              <template v-if="mutedCall">
                <v-icon>mdi-microphone-off </v-icon>
              </template>
              <template v-else>
                <v-icon>mdi-microphone</v-icon>
              </template>
            </v-btn>
            <v-btn icon class="btncall" color="error" @click="minhangup"
              ><v-icon class="icon-phone mdi-rotate-135" large>
                mdi-phone
              </v-icon></v-btn
            >

            <v-btn class="btnmax" @click="maximizeWin" icon color="warning"
              ><v-icon>mdi-window-maximize</v-icon></v-btn
            >
          </v-col>
        </v-row>
      </div>
    </v-snackbar>
  </div>
</template>
<script>
import Dialer from "@/components/Dialer/Dialer.vue";
import CallInProgress from "@/views/CallCenter/CallInProgress.vue";
import { mapGetters, mapMutations, mapState } from "vuex";
import { notifyInfo } from "@/components/Notification";
import { BtnOnOffMicrophone, BtnCall } from "@/components/buttons";
import Call from "@/mixins/call";
export default {
  name: "make-call",
  mixins: [Call],
  components: {
    Dialer,
    CallInProgress,
    BtnOnOffMicrophone,
    BtnCall,
  },
  data() {
    return {
      dialogMakecall: false,
      callUi: 0,
      number: "",
      mini: false,
      right: true,
      left: false,
      numberPatient: "",
      number: "",
      leadUuid: "",
      message: "",
    };
  },
  watch: {
    dialogmakeCall: {
      handler() {
        this.dialogMakecall = this.dialogmakeCall;
        if (this.mutDialogmakeCall) {
          this.number = localStorage.getItem("number")
            ? localStorage.getItem("number")
            : "";
          this.makeCall();
        }

        if (!this.dialogmakeCall) {
          this.mini = false;
          this.right = true;
          this.left = false;
        }
      },
    },
  },
  computed: {
    ...mapState("crmCallCenterModule", ["dialogmakeCall"]),
    ...mapGetters({ methodCall: "getMethodCall" }),
    ...mapState(["callTime", "mutedCall"]),
    colors() {
      switch (this.message) {
        case "callInProgress":
          return "green";

        case "errorcall":
          return "red";

        default:
          return "blue";
      }
    },
  },
  methods: {
    ...mapMutations("crmCallCenterModule", ["mutDialogmakeCall"]),
    toRight() {
      this.right = true;
      this.left = false;
    },
    toLeft() {
      this.right = false;
      this.left = true;
    },
    toClose() {
      this.mutDialogmakeCall(false);
    },
    toMini() {
      this.right = false;
      this.left = false;
      this.mini = true;
    },
    maximizeWin() {
      this.right = true;
      this.left = false;
      this.mini = false;
    },
    makeCall(number) {
      if (number) {
        this.number = number;
      }
      if (this.number == "") {
        this.callUi = 0;
      } else {
        this.callUi = 1;
      }
    },
    minMute() {
      this.$refs.callprogres.progmute();
    },
    minhangup() {
      this.$refs.callprogres.hangup();
    },
    hangup() {
      this.number = "";
      this.callUi = 0;
      this.mini = false;
      this.right = true;
      this.left = false;
      this.mutDialogmakeCall(false);
      notifyInfo(this.$t("crmCallCenter.notificationCallFinish"));
    },
    errorcaller() {
      //
    },
  },
  destroyed() {
    this.mutDialogmakeCall(false);
  },
};
</script>
<style lang="scss" scoped>
.aside-call {
  // background-image: url("/img/drawer-bg.svg");
  background-position: bottom;
  background-size: 100%;
}
.cloudcall {
  background-color: #424242 !important;
  background: #424242 !important;
  max-height: 40px !important;
  position: relative;
  border-radius: 25px !important;
}
.snackcloud {
}
.snackcall {
  border: 1px solid red !important;
  padding: 0 1px !important;
  width: 500px !important;
}

.mutecall {
  position: absolute;
  top: 0px;
  left: 170px !important;
}
.btncall {
  position: absolute;
  top: 2px;
  right: 60px;
}

.btnmax {
  position: absolute;
  top: 0px;
  right: 0px;
}
</style>

<i18n>
{
  "en": {
    "title": "Make a call",
    "errorcall":"Error in Call, Please Retry!!",
    "conect":"Conecting...",
    "connected": "Connected {time}",
    "callingTo": "Calling "
  },
  "es": {
    "title": "Haz una llamada",
    "errorcall":"Error en la Llamada, vuelve a intentar!!",
    "conect":"Conectando...",
    "connected": "Conectado {time}",
    "callingTo": "Llamando a"
  }
}
</i18n>

import { RouteConfig } from "vue-router";
import { Permission } from "@/utils/permissions";

const WorkersRoutes: Array<RouteConfig> = [
  {
    path: "",
    name: "Workers main",
    component: () => import("@/views/Employees/Employees.vue"),
    meta: {
      permissionsRequired: [Permission.LIST_CLIENT],
      requiresLogged: true,
    },
  },
  {
    path: "/employees/edit/:uuid",
    name: "Worker_Edit",
    component: () => import("@/views/Employees/EditEmployee.vue"),
    meta: {
      requiresLogged: true,
    },
  },
  {
    path: "/employees/create",
    name: "Worker_create",
    component: () => import("@/views/Employees/buttons/BtnCreateEmployee.vue"),
    meta: {
      requiresLogged: true,
    },
  },
];

export default WorkersRoutes;

<template>
  <v-app>
    <v-main
      v-if="isLogin"
      v-cloak
      style="background-color: var(--crm-background)"
    >
      <router-view name="Login" v-if="!waiting"></router-view>
    </v-main>

    <template v-if="isLogin == false && isPdfSign == false">
      <template v-if="isExternal == false && isLogin == false && loggedIn">
        <v-navigation-drawer
          v-model="drawer"
          class="sidebar"
          :mini-variant="drawerExpanded"
          app
          v-cloak
          @update:mini-variant="mutDrawerExpand($event)"
          @input="onInput"
        >
          {{/* To collapse the drawer */}}
          <v-list-item class="px-2 cursor-pointer" dark>
            <v-list-item-avatar color="blue">
              <v-img v-if="profile.avatar" :src="avatar"></v-img>
              <span v-else class="white--text headline">
                {{
                  profile.username != undefined
                    ? profile.username.slice(0, 2)
                    : ""
                }}
              </span>
            </v-list-item-avatar>

            <v-list-item-title class="text-truncate">
              <v-layout column>
                <span>{{ profile.fullname }}</span>
                <span class="text-caption">
                  {{ profile.phone }}
                </span>
              </v-layout>
            </v-list-item-title>

            <v-btn icon @click.stop="toggleDrawerExpand">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </v-list-item>
          <v-divider />
          {{/* Navigation */}}
          <the-aside />
        </v-navigation-drawer>
      </template>

      <v-main style="background-color: var(--crm-background)" v-cloak>
        <main-toolbar :appBarProps="{ value: topBar }" />
        <router-view v-if="!waiting"></router-view>
      </v-main>
      <make-call />
      <sms-global />

      <dialog-incomming-call
        v-model="callCenterProvider.incomingCall"
        @hangup="callCenterProvider.incomingCall = false"
      />
    </template>

    <v-main
      v-if="isPdfSign"
      class=""
      style="background-color: var(--crm-background)"
      v-cloak
    >
      <router-view name="AppSteps" class="fill-height"></router-view>
    </v-main>

    <notification />
  </v-app>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapMutations, mapState, mapActions } from "vuex";
import TheAside from "./components/layout/TheAside.vue";
import { MainToolbar } from "@/components/layout";
import store from "@/store/index";
import Notification from "./components/Notification/Notification.vue";
import DialogIncommingCall from "./views/CallCenter/DialogIncommingCall.vue";
import { ACT_GENERAL_CONFIG } from "@/store/actions/types";
import { MUT_DRAWER_EXPAND } from "./store/mutations/types";
import { notifyError } from "./components/Notification";
import MakeCall from "./views/CallCenter/components/MakeCall.vue";
import SmsGlobal from "@/views/SMS/components/SmsGlobal.vue";
// const AsidePatientDetails = () =>
//   import(
//     /* webpackChunkName: "aside-patient-details" */ "@/components/layout/AsidePatientDetails.vue"
//   );
// import { notifyError } from "./components/Notification";
// import i18n from "./i18n";

export default Vue.extend({
  name: "App",
  inject: ["callCenterProvider"],
  components: {
    TheAside,
    MainToolbar,
    Notification,
    DialogIncommingCall,
    MakeCall,
    SmsGlobal,
    // AsidePatientDetails,
  },
  async mounted() {
    try {
      if (this.loggedIn && (this.isWorker || this.isAdmin || this.isSuper)) {
        this.updateactivesms(true);
        setTimeout(() => {
          this.$root.initDevice();
        }, 2000);
      }
    } catch (error) {
      notifyError("An error occurred while the device is loading");
    }

    if (!this.$route.path.includes("register")) {
      await this.act_config();
      this.$vuetify.theme.themes.light = this.dataCRM.themes.light;
      window.sheduleMessageRequest = 0;
      this.waiting = false;
    }
  },
  data: () => ({
    waiting: true,
    drawer: false,
    topBar: false,
    interval: null,
  }),
  computed: {
    ...mapState([
      "drawerExpanded",
      "profile",
      "showDrawer",
      "dataCRM",
      "avatar",
      "activesms",
    ]),
    ...mapGetters(["loggedIn", "isWorker", "isAdmin", "isSuper"]),
    isLogin() {
      return this.$route.name == "auth";
    },
    isPdfSign() {
      return this.$route.path.includes("patient/check");
    },
    isExternal() {
      return this.$route.path.includes("register");
    },
  },

  methods: {
    ...mapMutations([MUT_DRAWER_EXPAND, "mutShowDrawer", "updateactivesms"]),
    ...mapActions({ act_config: ACT_GENERAL_CONFIG }),

    ...mapActions("crmSMSModule", ["getUnread"]),
    ...mapMutations("crmSMSModule", ["mutContainUnread"]),

    toggleDrawerExpand() {
      this.mutDrawerExpand(!this.drawerExpanded);
    },
    onInput(state) {
      this.mutShowDrawer(state);
    },

    getUnreadSms() {
      this.getUnread();
    },
    initSms() {
      if (this.activesms == true) {
        this.interval = setInterval(this.getUnreadSms, 5000);
      }
    },
  },
  watch: {
    // To sync the expanded state
    drawerExpanded(val) {
      this.mutDrawerExpand(val);
    },
    showDrawer(val) {
      this.drawer = val;
    },

    activesms(value) {
      if (value == false) {
        clearInterval(this.interval);
        this.mutContainUnread(false);
      } else {
        this.initSms();
      }
    },
  },
});
</script>
